import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Loading from "../src/components/common/Loading";
import { getStaticPropsForMessages } from "../src/util/utilServer";

export default function Home() {
  const { status } = useSession();
  const router = useRouter();
  let content: null | JSX.Element;
  switch (status) {
    case "authenticated":
      content = null;
      router.push("/dashboard" + window.location.search);
      break;
    case "unauthenticated":
      content = null;
      router.push("/auth/login" + window.location.search);
      break;
    case "loading":
      content = <Loading />;
      break;
    default:
      content = null;
  }

  return content;
}

Home.Messages = [] as string[];

export const getStaticProps = getStaticPropsForMessages(Home.Messages);
